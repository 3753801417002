<template>
	<v-sheet class="create-Customer create-page" id="customer" style="margin: 0 -12px">
		<div class="container">
			<v-row class="sticky">
				<v-col md="6" class="my-auto">
					<h1 class="custom-header-blue-text m-0">{{ pageTitle }}</h1>
				</v-col>
				<v-col md="6" class="text-right">
					<v-btn
						:loading="pageLoading"
						class="white--text mr-2"
						depressed
						color="blue darken-4"
						tile
						:disabled="pageLoading || !formValid"
						@click="createOrUpdateCustomer"
					>
						Save
					</v-btn>
					<v-btn :disabled="pageLoading" depressed tile @click="goBack"> Cancel </v-btn>
				</v-col>
			</v-row>
			<v-form
				ref="customer_submit"
				v-model.trim="formValid"
				lazy-validation
				v-on:submit.stop.prevent="createOrUpdateCustomer"
			>
				<v-row>
					<v-col cols="11">
						<div class="form-grp-title">
							<div>Company detail</div>
							<small>
								<v-icon size="16">mdi-progress-question</v-icon> Specify the company details for your Leads
							</small>
						</div>
						<v-row class="mb-3 px-4">
							<v-col lg="4" cols="12" class="my-auto py-0">
								<label class="input-label required">Sales Person</label>
							</v-col>
							<v-col lg="8" class="my-auto py-0">
								<v-autocomplete
									:items="usersList"
									item-text="display_name"
									item-value="id"
									depressed
									placeholder="Sales Person"
									:readonly="currentUser.role != 1 && currentUser.role != 4 && userType != 'accounts'"
									hide-details
									outlined
									hide-selected
									class="cuatom-drop"
									multiple
									color="blue darken-4"
									small-chips
									deletable-chips
									v-model="customer.attendees"
									:rules="[vrules.required(customer.attendees, 'Sales Person')]"
									:class="{
										required: !customer.attendees.length,
									}"
								>
									<template #selection="{ item, attrs, selected, select }">
										<v-chip
											small
											v-bind="attrs"
											:input-value="selected"
											close
											@click="select"
											@click:close="remove(item)"
										>
											<v-avatar left>
												<img v-if="getValue(item, 'profile_img')" :src="getValue(item, 'profile_img')" />
												<img v-else src="https://ams-dev.businessthrust.com//media/misc/no_photo_found.png" />
											</v-avatar>
											{{ getValue(item, "display_name") }}
										</v-chip>
									</template>
									<template #item="{ item }">
										<div class="d-flex align-center py-1">
											<v-avatar size="36">
												<img v-if="getValue(item, 'profile_img')" :src="getValue(item, 'profile_img')" />
												<img v-else src="https://ams-dev.businessthrust.com//media/misc/no_photo_found.png" />
											</v-avatar>
											<div class="ml-3 fw-500" :style="{ fontSize: '13px' }">
												<div class="text-uppercase">
													{{ item.display_name }}
												</div>
												<div class="text-muted">{{ item.email }}</div>
											</div>
										</div>
									</template>
								</v-autocomplete>
							</v-col>
						</v-row>
						<v-row class="mb-3 px-4">
							<v-col lg="4" cols="12" class="my-auto py-0">
								<label class="input-label required">Company Name</label>
							</v-col>
							<v-col lg="8" class="my-auto py-0">
								<v-text-field
									hide-details
									outlined
									placeholder="Company Name"
									v-model="customer.company_name"
									:rules="[vrules.required(customer.company_name, 'Company Name')]"
									:class="{
										required: !customer.company_name,
									}"
								>
								</v-text-field>
							</v-col>
						</v-row>
						<v-row class="mb-3 px-4">
							<v-col lg="4" cols="12" class="my-auto py-0">
								<label class="input-label">Company UEN </label>
							</v-col>
							<v-col lg="8" class="my-auto py-0">
								<v-text-field hide-details outlined placeholder="UEN Number" v-model="customer.company_uen">
								</v-text-field>
							</v-col>
						</v-row>
						<v-row class="mb-3 px-4">
							<v-col lg="4" cols="12" class="my-auto py-0">
								<label class="input-label">Industry</label>
							</v-col>
							<v-col lg="8" class="my-auto py-0 pr-10 position-relative">
								<!-- <v-text-field hide-details outlined placeholder="Industry" v-model="customer.industry">
								</v-text-field> -->

								<v-autocomplete
									:items="industrylist"
									item-text="text"
									item-value="value"
									hide-details
									outlined
									placeholder="Industry"
									v-model="customer.industry"
								>
								</v-autocomplete>
								<ManageAutocomplete title="Industry" typeId="55" v-on:update="getSettings">
								</ManageAutocomplete>
							</v-col>
						</v-row>
						<v-row class="mb-3 px-4">
							<v-col lg="4" cols="12" class="my-auto py-0">
								<label class="input-label">Website</label>
							</v-col>
							<v-col lg="8" class="my-auto py-0">
								<v-text-field hide-details outlined placeholder="Website" v-model="customer.website">
								</v-text-field>
							</v-col>
						</v-row>
						<v-row class="mb-3 px-4">
							<v-col lg="4" cols="12" class="my-auto py-0">
								<label class="input-label">Country</label>
							</v-col>
							<v-col lg="8" class="my-auto py-0 position-relative">
								<v-autocomplete
									:items="countryList"
									item-text="text"
									item-value="value"
									hide-details
									outlined
									placeholder="Country"
									v-model="customer.country"
									v-on:change="countryCheck($event)"
								>
								</v-autocomplete>
								<!-- <ManageAutocomplete title="Country" typeId="5" v-on:update="getSettings">
								</ManageAutocomplete> -->
							</v-col>
						</v-row>
						<v-row class="mb-3 px-4">
							<v-col lg="4" cols="12" class="my-auto py-0">
								<label class="font-size-16 font-weight-500">Search Address </label>
							</v-col>
							<v-col lg="8" class="my-auto ml-0 py-0 position-relative">
								<SearchAddress
									:key="searchKey"
									variant="outlined"
									density="compact"
									hide-details
									:country="customer.country"
									:country-code="country_code"
									@update:address="(data) => setAddress(data)"
									label="Search Address"
									placeholder="Search Address"
								/>
							</v-col>
						</v-row>
						<v-row class="mb-3 px-4">
							<v-col lg="4" cols="12" class="my-auto py-0">
								<label class="input-label">Postal Code</label>
							</v-col>
							<v-col lg="8" class="my-auto py-0">
								<v-text-field
									hide-details
									outlined
									placeholder="Postal code"
									v-model.trim="customer.postal_code"
									:rules="[vrules.maxLength(customer.postal_code, 'Postal Code', 10)]"
									v-mask="'######'"
									type="Number"
								>
								</v-text-field>
							</v-col>
						</v-row>
						<v-row class="mb-3 px-4">
							<v-col lg="4" cols="12" class="my-auto py-0">
								<label class="input-label">Unit No</label>
							</v-col>
							<v-col lg="8" class="my-auto py-0">
								<v-text-field
									hide-details
									outlined
									placeholder="Unit Number"
									v-model="customer.unit_number"
								>
								</v-text-field>
							</v-col>
						</v-row>
						<v-row class="mb-3 px-4">
							<v-col lg="4" cols="12" class="my-auto py-0">
								<label class="input-label">Address Line 1</label>
							</v-col>
							<v-col lg="8" class="my-auto py-0">
								<v-text-field
									hide-details
									outlined
									placeholder="Address Line 1"
									v-model="customer.address_line_1"
								>
								</v-text-field>
							</v-col>
						</v-row>
						<v-row class="mb-3 px-4">
							<v-col lg="4" cols="12" class="my-auto py-0">
								<label class="input-label">Address Line 2</label>
							</v-col>
							<v-col lg="8" class="my-auto py-0">
								<v-text-field
									hide-details
									outlined
									placeholder="Address Line 2"
									v-model="customer.address_line_2"
								>
								</v-text-field>
							</v-col>
						</v-row>

						<v-row class="mb-3 px-4">
							<v-col lg="4" cols="12" class="my-auto py-0">
								<label class="input-label">Source</label>
							</v-col>
							<v-col lg="8" class="my-auto py-0 pr-10">
								<v-autocomplete
									hide-details
									:items="sourceList"
									placeholder="Source"
									outlined
									v-model="customer.source"
									item-text="text"
									item-value="value"
								>
								</v-autocomplete>
								<ManageAutocomplete v-if="false" title="Source" typeId="2" v-on:update="getSettings">
								</ManageAutocomplete>
							</v-col>
						</v-row>
						<v-row class="mb-3 px-4">
							<v-col lg="4" cols="12" class="my-auto py-0">
								<label class="input-label">Remark</label>
							</v-col>
							<v-col lg="8" class="my-auto py-0 pr-10 position-relative">
								<TextAreaInput
									hide-details
									placeholder="Remark"
									v-model="customer.remark"
									:word-limit="250"
									:rows="5"
									@input="inputText"
								></TextAreaInput>
							</v-col>
						</v-row>
						<div class="form-grp-title d-flex">
							<div>Contact Person</div>
							<v-spacer></v-spacer>
							<v-btn
								color="blue darken-4"
								class="white--text my-auto"
								depressed
								tile
								@click="add_person()"
							>
								<v-icon left>mdi-plus</v-icon>
								Add new
							</v-btn>
						</div>
						<!-- <v-row class="mb-3 pl-4 pr-10">
							<v-col style="max-width: 60px">
								<label class="fw-500">Primary</label>
							</v-col>
							<v-col>
								<label class="fw-500 required">Contact Name</label>
							</v-col>
							<v-col>
								<label class="fw-500">Email Address</label>
							</v-col>
							<v-col>
								<label class="fw-500">Designation</label>
							</v-col>
						</v-row> -->

						<v-row>
							<template v-for="(data, index) in customer.contact_person">
								<v-col md="11" :key="index + '_' + 'contact_person_1'" cols="12">
									<v-row class="pl-4">
										<v-col style="max-width: 60px">
											<label class="fw-500 mb-3">Primary</label>
											<v-checkbox
												v-model="customer.contact_person[index].primary"
												hide-details
												style="margin-top: 0px !important"
												:readonly="getValue(customer, `contact_person.${index}.primary`) == true ? true : false"
												v-on:change="checkBoxtype(index)"
											></v-checkbox>
										</v-col>

										<v-col>
											<label class="fw-500 required mb-2">Contact Name</label>
											<v-text-field
												type="text"
												placeholder="Contact Name"
												v-model="customer.contact_person[index].contact_name"
												:rules="[
													vrules.required(
														getValue(customer, `contact_person.${index}.contact_name`),
														'Contact Name'
													),
												]"
												:class="{
													required: !getValue(customer, `contact_person.${index}.contact_name`),
												}"
												outlined
												hide-details
												color
											>
											</v-text-field>
										</v-col>

										<v-col>
											<label class="fw-500 mb-2">Email Address</label>
											<v-text-field
												type="email"
												placeholder="Email Address"
												v-model="customer.contact_person[index].email_address"
												:rules="[
													vrules.validEmail(
														getValue(customer, `contact_person.${index}.email_address`),
														'Email Address'
													),
												]"
												@blur="validateEmail(index)"
												outlined
												hide-details
												color
											>
											</v-text-field>
										</v-col>

										<v-col class="my-auto py-0 pr-10 position-relative">
											<label class="fw-500 mb-2">Designation</label>
											<v-autocomplete
												:items="designationList"
												item-text="text"
												item-value="value"
												hide-details
												outlined
												placeholder="Designation"
												v-model="customer.contact_person[index].designation"
											>
											</v-autocomplete>
											<ManageAutocomplete
												title="Designation"
												typeId="25"
												v-on:update="getSettings"
												customClass="mt-7"
											/>
										</v-col>
										<!-- <template v-if="customerId > 0 && index > 0 && customer.contact_person[index].primary == 0">
											<v-icon
												color="red"
												style="position: absolute; top: 6px; right: 16px"
												@click="delete_contact(customer.contact_person[index].id, index)"
												>mdi-delete</v-icon
											>
										</template>
										<template v-else>
											<v-icon
												v-if="customer.contact_person[index].primary == 0"
												color="red"
												style="position: absolute; top: 6px; right: 16px"
												@click="delete_person(index)"
												>mdi-delete</v-icon
											>
										</template> -->
									</v-row>
									<!-- </v-col>
								<v-col md="11" :key="index + '_' + 'contact_person_2'"> -->
									<v-row class="pl-4">
										<v-col style="max-width: 60px"> </v-col>

										<v-col class="mt-3">
											<label class="fw-500 mb-2">Phone No/Mobile No</label>
											<PhoneTemplate
												hide-details
												id="phone_number"
												placeholder="Phone No/Mobile No"
												v-model="customer.contact_person[index].phone_number"
												:loading="pageLoading"
												@loading="($event) => (pageLoading = $event)"
												@blur="validatePhone(index, 'phone_number')"
												@validity="phoneValid($event, index, 'phone_number')"
												@clear="customer.contact_person[index].phone_number = null"
											>
											</PhoneTemplate>
										</v-col>

										<v-col class="mt-3">
											<label class="fw-500 mb-2">DID</label>
											<PhoneTemplate
												hide-details
												id="did_number"
												placeholder="DID"
												v-model="customer.contact_person[index].did_number"
												:loading="pageLoading"
												@loading="($event) => (pageLoading = $event)"
												@blur="validatePhone(index, 'did_number')"
												@validity="phoneValid($event, index, 'did_number')"
												@clear="customer.contact_person[index].did_number = null"
											></PhoneTemplate>
										</v-col>

										<v-col class="mt-3 pr-10">
											<label class="fw-500 mb-2">Landline Number </label>
											<PhoneTemplate
												hide-details
												id="did_number"
												placeholder="Landline Number"
												v-model="customer.contact_person[index].land_line_number"
												:loading="pageLoading"
												@loading="($event) => (pageLoading = $event)"
												@blur="validatePhone(index, 'land_line_number')"
												@validity="phoneValid($event, index, 'land_line_number')"
												@clear="customer.contact_person[index].land_line_number = null"
											></PhoneTemplate>
										</v-col>
									</v-row>
								</v-col>
								<v-col class="d-flex align-center justify-center" md="1" :key="index + '_' + 'delete-btn'">
									<template
										v-if="
											customerId > 0 && index > 0 && getValue(customer, `contact_person.${index}.primary`) == 0
										"
									>
										<v-icon color="red" @click="delete_contact(customer.contact_person[index].id, index)"
											>mdi-delete</v-icon
										>
									</template>
									<template v-else>
										<v-icon
											v-if="getValue(customer, `contact_person.${index}.primary`) == 0"
											color="red"
											@click="delete_person(index)"
											>mdi-delete</v-icon
										>
									</template>
								</v-col>
								<v-col
									md="12"
									:key="index + '_' + 'divider'"
									v-if="index < customer.contact_person.length - 1"
								>
									<v-divider></v-divider>
								</v-col>
							</template>
						</v-row>

						<!-- 	<v-row>
							<v-col cols="3" lg="3" class="px-7">
								<v-btn
									color="blue darken-4"
									class="white--text my-auto"
									depressed
									tile
									@click="add_person()"
								>
									<v-icon left>mdi-plus</v-icon>
									Add new
								</v-btn>
							</v-col>
						</v-row> -->

						<div class="form-grp-title mt-3">
							<div>
								<div>Attachments</div>
								<small>
									<v-icon size="16">mdi-progress-question</v-icon> Specify the attachments for your Leads
								</small>
							</div>
						</div>
						<template v-for="(file, index) in files">
							<v-row class="mb-3 px-4" :key="index + '_' + 'files'">
								<v-col lg="5" cols="5" class="my-auto py-0 d-flex align-items-center">
									<v-file-input
										:id="`document-file-${index}`"
										placeholder="Select File"
										outlined
										class="mt-3"
										prepend-icon=""
										prepend-inner-icon="mdi-attachment"
										hide-details
										v-model="file.file"
										v-on:change="updateFile(index, $event)"
										v-on:click:clear="updateFile(index, $event)"
									></v-file-input>
								</v-col>
								<v-col lg="" cols="5" class="my-auto py-0 d-flex align-items-center">
									<TextInput
										:id="`document-name-${index}`"
										v-model="file.name"
										hide-details
										placeholder="File Name"
										:suffix="file.suffix"
									></TextInput>
								</v-col>
								<v-col lg="1" cols="1" class="my-auto py-0">
									<v-btn
										:disabled="files.length < 2"
										v-on:click="removeFile(index)"
										color="red lighten-1 white--text"
										class="mt-3"
										icon
									>
										<v-icon>mdi-delete</v-icon>
									</v-btn>
								</v-col>
								<v-col lg="1" cols="1" class="my-auto py-0">
									<v-btn
										color="blue darken-4 white--text"
										class="mt-2 ml-2"
										tile
										depressed
										v-on:click="addMore()"
									>
										<v-icon>mdi-plus</v-icon>
									</v-btn>
								</v-col>
							</v-row>
						</template>

						<div class="form-grp-title mt-3">
							<div>
								<div>Client Score</div>
								<small>
									<v-icon size="16">mdi-progress-question</v-icon> Specify the Client score for your Leads
								</small>
							</div>
						</div>
						<v-row class="mb-3 px-4">
							<v-col lg="4" cols="12" class="my-auto py-0">
								<label class="input-label">Renew</label>
							</v-col>
							<v-col lg="8" class="my-auto py-0">
								<v-rating
									v-model="customer.client_score_renew"
									background-color="blue darken-4"
									color="blue darken-4"
									hide-details
								></v-rating>
							</v-col>
						</v-row>
						<v-row class="mb-3 px-4">
							<v-col lg="4" cols="12" class="my-auto py-0">
								<label class="input-label">Full</label>
							</v-col>
							<v-col lg="8" class="my-auto py-0">
								<v-rating
									v-model="customer.client_score_full"
									background-color="blue darken-4"
									color="blue darken-4"
									hide-details
								></v-rating>
							</v-col>
						</v-row>
						<v-row class="mb-3 px-4">
							<v-col lg="4" cols="12" class="my-auto py-0">
								<label class="input-label">Base Only</label>
							</v-col>
							<v-col lg="8" class="my-auto py-0">
								<v-rating
									v-model="customer.client_score_base"
									background-color="blue darken-4"
									color="blue darken-4"
									hide-details
								></v-rating>
							</v-col>
						</v-row>
					</v-col>
				</v-row>
			</v-form>
		</div>
	</v-sheet>
</template>

<script>
import { mapGetters } from "vuex";
import { POST, GET, DELETE } from "@/core/services/store/request.module";
import { SET_MESSAGE, SET_ERROR } from "@/core/services/store/common.module";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import MainMixin from "@/core/mixins/main.mixin.js";
import { map, forEach, find } from "lodash";
import objectPath from "object-path";

export default {
	mixins: [MainMixin],
	name: "Create-Customer",
	data() {
		return {
			country: "singapore",
			country_code: "sg",
			pageTitle: "Create New Customer",
			formValid: true,
			today: new Date(),
			date_menu: false,
			customerId: null,
			leadData: [],
			pageLoading: false,
			currencyList: [],
			//	openUploadModel:false,
			full_rating: 4,
			base_only_rating: 2,
			renew_rating: 3,

			dataTab: null,
			checkData: [],
			usersList: [],
			lead_id: 0,
			sourceList: [],
			target_list: [],
			customer: {
				company_name: null,
				attendees: [],
				source: "web-enquiry",
				server: null,
				company_uen: null,
				industry: null,
				website: null,
				postal_code: null,
				address_line_1: null,
				address_line_2: null,
				unit_number: null,
				country: "singapore",
				currency: "sgd",
				billing_type: "single_billing",
				amount: null,
				checkbox_type: [],
				seo_type: "combined",
				contract_date: null,
				lead: 0,
				remark: null,
				sales_date: null,
				client_score_renew: null,
				client_score_full: null,
				client_score_base: null,
				contact_person: [
					{
						id: 0,
						contact_name: null,
						email_address: null,
						phone_number: null,
						country_code: null,
						designation: null,
						primary: true,
						did_number: null,
						land_line_number: null,
					},
				],
			},

			statusList: [],
			countryList: [
				{
					text: "Singapore",
					value: "singapore",
					country_code: "sg",
				},
				{
					text: "Malaysia",
					value: "malaysia",
					country_code: "my",
				},
				{
					text: "Myanmar",
					value: "myanmar",
					country_code: "mm",
				},
				{
					text: "India",
					value: "india",
					country_code: "in",
				},
				{
					text: "China",
					value: "china",
					country_code: "cn",
				},
				{
					text: "Australia",
					value: "australia",
					country_code: "at",
				},
				{
					text: "American",
					value: "american",
					country_code: "as",
				},
				{
					text: "UK",
					value: "uk",
					country_code: "gb",
				},
				{
					text: "Canada",
					value: "canada",
					country_code: "ca",
				},
			],
			industrylist: [],

			files: [
				{
					file: null,
					name: null,
				},
			],
			phoneCodeList: [],
			designationList: [],
			serverProvider: [],
			serverWeb: [],
			phoneValidity: [],
			searchKey: +new Date(),
		};
	},
	components: {
		/* ManageAutocomplete,
		PhoneTemplate,
		TextAreaInput,
		TextInput,
		SearchAddress, */

		ManageAutocomplete: () => import("@/view/pages/leads/components/ManageAutocomplete"),
		PhoneTemplate: () => import("@/view/components/Phone.vue"),
		TextInput: () => import("@/view/components/TextInput"),
		TextAreaInput: () => import("@/view/components/TextAreaInput"),
		SearchAddress: () => import("@/view/components/Search-Address"),
	},
	methods: {
		// countryCheck(data) {
		// 	let code = "";
		// 	//let updatedData = find(data, (row) => row.status.value == status);

		// 	code = find(this.countryList, function (row) {

		// 		if (row.value == data) {
		// 			return row.country_code;
		// 		} else {
		// 			return false;
		// 		}
		// 	});
		// 	this.country_code = code.country_code;

		// 	this.customer.postal_code = null;
		// 	this.customer.address_line_1 = null;
		// 	this.customer.address_line_2 =null;
		// 	this.customer.unit_number = null;
		// 	//  this.propertyAddress.longitude = null;
		// 	//this.propertyAddress.latitude = null;
		// },
		countryCheck(data) {
			/* To clear the field */
			this.searchKey = +new Date();

			let code = "";
			code = find(this.countryList, function (row) {
				//console.log(row);
				if (row.value == data) {
					return row.country_code;
				} else {
					return false;
				}
			});

			this.country_code = code.country_code;
			this.customer.postal_code = null;
			this.customer.address_line_1 = null;
			this.customer.address_line_2 = null;
			this.customer.unit_number = null;
			this.country = data;
			this.customer.country = data;
			//console.log(this.country_code);
		},
		setAddress(e) {
			console.log({ e });

			this.customer.postal_code = e.postal_code;
			this.customer.address_line_1 = e.address1 && e.address1 != "NIL" ? e.address1 : "";
			this.customer.address_line_2 = e.address2 && e.address2 != "NIL" ? e.address2 : "";
			this.customer.unit_number = e.unit_number;
			// this.row.longitude = e.longitude;
			// this.row.latitude = e.latitude;
		},

		getValue(object, key) {
			return objectPath.get(object, key);
		},
		hasKey(object, key) {
			return objectPath.has(object, key);
		},
		inputText(evt) {
			console.log({ evt });
		},
		singleLead() {
			this.$store
				.dispatch(GET, { url: `lead/${this.lead_id}` })
				.then((data) => {
					this.customer.attendees = [data.lead.assign];
					this.customer.company_name = data.lead.company_name;
					this.customer.remark = data.lead.remark;
					this.customer.website = data.lead.website;
					this.customer.source = data.lead.source;
					this.customer.unit_number = data.lead.unit_number;
					this.customer.postal_code = data.lead.postal_code;
					this.customer.address_line_1 = data.lead.address_line_1;
					this.customer.address_line_2 = data.lead.address_line_2;
					this.customer.country = data.lead.country;
					this.customer.contact_person = map(data.contact, (row) => {
						return { ...row, id: 0, lead_id: 0 };
					});
				})
				.catch((error) => {
					console.log({ error });
				});
		},
		checkBoxtype(index) {
			let falseData = map(this.customer.contact_person, (row, rowIndex) => {
				if (rowIndex != index) {
					row.primary = false;
				}
				return row;
			});
			this.customer.contact_person = falseData;
		},
		delete_contact(id, index) {
			if (id > 0) {
				this.$store
					.dispatch(DELETE, {
						url: `customer-contact/${this.customerId}/${id}`,
					})
					.then(() => {
						this.$store.commit(SET_MESSAGE, [
							{
								model: true,
								message: "Success ! Contact deleted successfully.",
							},
						]);
						this.getCustomer();
					})
					.catch((error) => {
						console.log({
							error,
						});
					});
			} else {
				this.customer.contact_person.splice(index, 1);
			}
		},
		createOrUpdateCustomer() {
			const formErrors = this.validateForm(this.$refs["customer_submit"]);

			this.$refs["customer_submit"].validate();

			if (formErrors.length) {
				this.$store.commit(SET_ERROR, this.errors.concat(formErrors));
				return false;
			}

			if (!this.$refs["customer_submit"].validate()) {
				return false;
			}

			forEach(this.customer.contact_person, (row) => {
				let emailsObj = this.customer.contact_person.filter(function (contactpersn) {
					if (contactpersn.email_address == row.email_address) {
						return contactpersn.email_address;
					}
				});
				let phonrObj = this.customer.contact_person.filter(function (contactpersn) {
					if (contactpersn.phone_number == row.phone_number) {
						return contactpersn.phone_number;
					}
				});

				if (emailsObj.length > 1) {
					this.$store.commit(SET_ERROR, [
						{ model: true, message: `Contact email ${row.email_address} already exists` },
					]);
					this.formValid = false;
					return false;
				}
				if (phonrObj.length > 1) {
					this.$store.commit(SET_ERROR, [
						{ model: true, message: `Contact phone number ${row.phone_number} already exists` },
					]);
					this.formValid = false;
					return false;
				}
			});
			if (!this.formValid) {
				return false;
			}
			/* let primary_email = validate.email_address;
			let contactId = validate.id;
			
			var emailsObj = this.customer.contact_person.filter(function (contactpersn) {
			
			if (contactpersn.email_address == primary_email && (contactpersn.id != contactId || contactId == null)
				) {
				return contactpersn.email_address;
				}
			}); */

			/* checks whether all contact numbers are valid in all contact persons. Dont let form to submit if invalid contact number. */
			if (this.phoneValidity.length) {
				let ErrArr = this.phoneValidity.filter(Boolean);
				let phoneExCheck = ErrArr.find((isVal) => {
					if (isVal)
						return (
							isVal.phone_number == false || isVal.did_number == false || isVal.land_line_number == false
						);
				});

				if (phoneExCheck) {
					if (
						phoneExCheck.phone_number == false ||
						phoneExCheck.did_number == false ||
						phoneExCheck.land_line_number == false
					) {
						this.formValid = false;
						return false;
					}
				}
			}

			this.pageLoading = true;

			let formData = new FormData();

			formData.append("company_name", this.customer.company_name ? this.customer.company_name : null);

			if (this.customer.company_uen) {
				formData.append("company_uen", this.customer.company_uen);
			}
			if (this.customer.industry) {
				formData.append("industry", this.customer.industry);
			}
			if (this.customer.website) {
				formData.append("website", this.customer.website);
			}

			if (this.customer.postal_code) {
				formData.append("postal_code", this.customer.postal_code);
			}
			if (this.customer.address_line_1) {
				formData.append("address_line_1", this.customer.address_line_1);
			}
			if (this.customer.address_line_2) {
				formData.append("address_line_2", this.customer.address_line_2);
			}
			if (this.customer.source) {
				formData.append("source", this.customer.source);
			}
			if (this.customer.unit_number) {
				formData.append("unit_number", this.customer.unit_number);
			}
			if (this.customer.billing_type) {
				formData.append("billing_type", this.customer.billing_type);
			}
			if (this.customer.remark) {
				formData.append("remark", this.customer.remark);
			}
			if (this.customer.amount) {
				formData.append("amount", this.customer.amount);
			}
			if (this.customer.country) {
				formData.append("country", this.customer.country);
			}
			if (this.customer.currency) {
				formData.append("currency", this.customer.currency);
			}

			if (this.customer.client_score_renew) {
				formData.append("client_score_renew", this.customer.client_score_renew);
			}
			if (this.customer.client_score_full) {
				formData.append("client_score_full", this.customer.client_score_full);
			}
			if (this.customer.client_score_base) {
				formData.append("client_score_base", this.customer.client_score_base);
			}
			for (let i = 0; i < this.customer.attendees.length; i++) {
				if (this.customer && this.customer.attendees && this.customer.attendees[i]) {
					formData.append(`attendees[${i}]`, this.customer.attendees[i]);
				}
			}
			formData.append("lead", this.lead_id ? this.lead_id : this.customer.lead);

			for (let i = 0; i < this.customer.contact_person.length; i++) {
				formData.append(
					`contact_person[${i}][id]`,
					objectPath.get(this.customer, `contact_person.${i}.id`)
						? objectPath.get(this.customer, `contact_person.${i}.id`)
						: 0
				);

				formData.append(
					`contact_person[${i}][contact_name]`,
					objectPath.get(this.customer, `contact_person.${i}.contact_name`)
				);

				if (objectPath.get(this.customer, `contact_person.${i}.email_address`)) {
					formData.append(
						`contact_person[${i}][email_address]`,
						objectPath.get(this.customer, `contact_person.${i}.email_address`)
					);
				}
				if (objectPath.get(this.customer, `contact_person.${i}.phone_number`)) {
					formData.append(
						`contact_person[${i}][phone_number]`,
						objectPath.get(this.customer, `contact_person.${i}.phone_number`)
					);
				}
				if (objectPath.get(this.customer, `contact_person.${i}.did_number`)) {
					formData.append(
						`contact_person[${i}][did_number]`,
						objectPath.get(this.customer, `contact_person.${i}.did_number`)
					);
				}
				if (objectPath.get(this.customer, `contact_person.${i}.land_line_number`)) {
					formData.append(
						`contact_person[${i}][land_line_number]`,
						objectPath.get(this.customer, `contact_person.${i}.land_line_number`)
					);
				}
				if (objectPath.get(this.customer, `contact_person.${i}.country_code`)) {
					formData.append(
						`contact_person[${i}][country_code]`,
						objectPath.get(this.customer, `contact_person.${i}.country_code`)
					);
				}
				if (objectPath.get(this.customer, `contact_person.${i}.designation`)) {
					formData.append(
						`contact_person[${i}][designation]`,
						objectPath.get(this.customer, `contact_person.${i}.designation`)
					);
				}
				if (objectPath.get(this.customer, `contact_person.${i}.primary`)) {
					formData.append(
						`contact_person[${i}][primary]`,
						objectPath.get(this.customer, `contact_person.${i}.primary`)
					);
				}
			}

			for (let i = 0; i < this.files.length; i++) {
				if (this.files && this.files[i] && this.files[i].file) {
					formData.append(`file[${i}][file]`, this.files[i].file);
				}
				if (this.files && this.files[i] && this.files[i].name) {
					formData.append(`file[${i}][name]`, this.files[i].name);
				}
			}

			let requestTYPE = POST;
			let requestURL = "customer";

			if (this.customerId) {
				requestURL = `customer/${this.customerId}`;
			}

			this.$store
				.dispatch(requestTYPE, {
					url: requestURL,
					data: formData,
				})
				.then(() => {
					if (this.customerId > 0) {
						this.$store.commit(SET_MESSAGE, [
							{
								model: true,
								message: "Success ! Customer updated successfully.",
							},
						]);
						this.$router.push({
							name: `customer-detail2`,
							params: this.customerId,
							query: { t: new Date().getTime() },
						});
					} else {
						this.$store.commit(SET_MESSAGE, [
							{
								model: true,
								message: "Success ! Customer created successfully.",
							},
						]);
						this.$router.push({
							name: `customer`,
							// params: data.id,
							query: { t: new Date().getTime() },
						});
					}
					this.$refs["customer_submit"].reset();
				})
				.catch((error) => {
					console.log({
						error,
					});
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		getSettings() {
			const _this = this;
			_this.$store
				.dispatch(GET, {
					url: "customer-setting",
				})
				.then((data) => {
					if (Array.isArray(data.sales_users) && data.sales_users.length) {
						_this.usersList = data.sales_users;
					}
					// (_this.countryList = data.country);
					_this.currencyList = data.currency;
					_this.industrylist = data.industry;
					_this.phoneCodeList = data.code;
					_this.designationList = data.designation;
					_this.serverProvider = data.serverProvider;
					_this.serverWeb = data.server;
					_this.payment_list = data.payment_list;
					_this.target_list = data.target_list;
					_this.sourceList = data.source;
				})
				.catch((error) => {
					console.log({
						error,
					});
				})
				.finally(() => {
					_this.pageLoading = false;
				});
		},
		getCustomer() {
			this.$store
				.dispatch(GET, {
					url: `single-customer/${this.customerId}`,
				})
				.then((data) => {
					this.checkData = data;
					console.log("data.customer.remark => ", data.customer.remark);
					this.customer.company_name = data.customer.company_name;
					this.customer.company_uen = data.customer.company_uen;
					this.customer.industry = data.customer.industry;
					this.customer.lead = data.customer.lead;
					this.customer.website = data.customer.website;
					this.customer.postal_code = data.customer.postal_code;
					this.customer.source = data.customer.source;
					this.customer.address_line_1 = data.customer.address_line_1;
					this.customer.address_line_2 = data.customer.address_line_2;
					this.customer.unit_number = data.customer.unit_number;
					this.customer.country = data.customer.country;
					this.customer.billing_type = data.customer.billing_type;
					this.customer.remark = objectPath.get(data, "customer.remark")
						? data.customer.remark.replaceAll("\r", "")
						: null;
					this.customer.amount = data.customer.amount;
					this.customer.currency = data.customer.currency;
					this.customer.checkbox_type = data.customer.checkbox_type;
					this.customer.seo_type = data.customer.seo_type;
					this.customer.contract_date = data.customer.contract_date;
					this.customer.sales_date = data.customer.sales_date;
					this.customer.client_score_renew = data.customer.client_score_renew;
					this.customer.client_score_full = data.customer.client_score_full;
					this.customer.client_score_base = data.customer.client_score_base;
					/* this.customer.contact_person = data.contact_person; */
					this.customer.attendees = map(data.attendie[0].attendies, "id");
					this.customer.contact_person = map(data.contact_person, (row) => {
						return { ...row, primary: row.primary == 0 ? false : true };
					});

					/* 	this.customer.contact_person =  */
				})
				.catch((error) => {
					console.log({
						error,
					});
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		add_person() {
			this.customer.contact_person.push({
				contact_name: null,
				email_address: null,
				phone_number: null,
				country_code: "SG",
				designation: null,
				did_number: null,
				land_line_number: null,
				primary: false,
				id: 0,
			});
		},
		delete_person(index) {
			this.customer.contact_person.splice(index, 1);
		},
		remove(item) {
			if (this.currentUser.role != 1 && this.currentUser.role != 4 && this.userType != "accounts") {
				return false;
			} else {
				const index = this.customer.attendees.indexOf(item.id);
				this.customer.attendees.splice(index, 1);
			}
		},
		validateEmail(index) {
			let primary_email = this.customer.contact_person[index].email_address;
			var emailsObj = this.customer.contact_person.filter(function (contactpersn) {
				if (contactpersn.email_address == primary_email) {
					return contactpersn.email_address;
				}
			});

			if (emailsObj.length > 1) {
				this.$store.commit(SET_ERROR, [
					{ model: true, message: `Contact email ${primary_email} already exists` },
				]);
				this.formValid = false;
				return false;
			}

			this.formValid = true;
		},
		validatePhone(index, type) {
			let primary_phone = this.customer.contact_person[index][type];
			/* let contactId = this.customer.contact_person[index].id; */

			var phoneObj = this.customer.contact_person.filter(function (contactpersn) {
				if (contactpersn[type] == primary_phone) {
					return contactpersn[type];
				}
			});

			if (phoneObj.length > 1) {
				this.$store.commit(SET_ERROR, [
					{
						model: true,
						message: `Contact ${this.mod_string(type, "-")} ${primary_phone} already exists`,
					},
				]);
				this.formValid = false;
				return false;
			}
			this.formValid = true;
		},
		phoneValid(validity, index, type) {
			if (!this.phoneValidity[index]) {
				this.phoneValidity[index] = {};
			}
			this.phoneValidity[index][type] = validity;
		},
		/* uploadFile(data) {
			console.log(data)
			this.fileList.push(data);
		}, */
		/* selectFile() {
			this.$refs["uploadFile"].$refs["input"].click();
		}, */
		updateFile(index, file, value, data) {
			if (value == "keyword") {
				if (file && file.name) {
					data[index].name = file.name.split(".").slice(0, -1).join(".");
					data[index].suffix = `.${file.name.split(".").pop()}`;
				} else {
					data[index].name = null;
					data[index].suffix = null;
				}
			} else {
				if (file && file.name) {
					this.files[index].name = file.name.split(".").slice(0, -1).join(".");
					this.files[index].suffix = `.${file.name.split(".").pop()}`;
				} else {
					this.files[index].name = null;
					this.files[index].suffix = null;
				}
			}
		},
		addMore() {
			this.files.push({
				file: null,
				name: null,
			});
		},

		removeFile(index) {
			this.files.splice(index, 1);
			if (this.files.length < 1) {
				this.addMore();
			}
		},
		/* remove_file(index) {
			this.fileList.splice(index, 1);
		}, */
	},
	computed: {
		...mapGetters(["errors", "currentUser", "userType", "moduleSettings"]),
	},
	mounted() {
		if (!this.getPermission("customer:create") && this.customerId == 0) {
			this.$router.replace({
				name: "customer",
				query: { t: new Date().getTime() },
			});
		}
		if (!this.getPermission("customer:update") && this.customerId > 0) {
			this.$router.replace({
				name: "customer",
				query: { t: new Date().getTime() },
			});
		}
		this.getSettings();
		if (this.customerId) {
			this.getCustomer();
			this.pageTitle = "Update Customer";
		}
		if (!this.customerId) {
			this.customer.attendees = [this.userId];
		}
		this.$store.dispatch(SET_BREADCRUMB, [
			// { text: "Dashboard", disabled: false, href: "/dashboard" },
			{ text: "Customer", disabled: false, href: "/contacts/customer" },
			{ text: "Create", disabled: true },
		]);

		if (this.customerId) {
			this.$store.dispatch(SET_BREADCRUMB, [
				// { text: "Dashboard", disabled: false, href: "/dashboard" },
				{ text: "Customer", disabled: false, href: "/contacts/customer" },
				{ text: "Update", disabled: true },
			]);
		}
		const { query } = this.$route;
		this.lead_id = query.lead_id;

		if (this.lead_id > 0) {
			this.singleLead();
		}
	},
	beforeMount() {
		this.customerId = this.$route.params.id;
	},
};
</script>
